type Callback = (SortableEvent: import('@github/sortablejs').SortableEvent) => unknown

export function moveWithButton(button: HTMLElement, item: HTMLElement, cb: Callback) {
  const direction = button.getAttribute('data-direction')
  const oldIndex = siblings(item).indexOf(item)

  if (direction === 'up' && item.previousElementSibling) {
    item.previousElementSibling.insertAdjacentElement('beforebegin', item)
  } else if (direction === 'down' && item.nextElementSibling) {
    item.nextElementSibling.insertAdjacentElement('afterend', item)
  }

  const newIndex = siblings(item).indexOf(item)
  button.focus()
  cb({oldIndex, newIndex, item})
}

function siblings(el: Element): Node[] {
  const parent = el.parentElement!
  return Array.from(parent.children)
}
